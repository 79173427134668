/* Base styles for all screen sizes */
.App {
  margin: 0;
  pading: 0;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  padding-top: 20px;
}

.App-header {
  background-color: #ede8de;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #597eb7;
}

.App-link {
  color: #597eb7;
  text-decoration: none;
  padding: 10px 20px;
}
.App-link:hover {
  text-decoration: line-through;
  font-weight: bold;
}

/* Styles for tablets (e.g., iPad) */
@media (max-width: 768px) {
  .App-logo {
    height: 40vmin;
  }

  .App-header {
    /* Modify .App-header styles for tablets */
    min-height: 100vh;
  }
}

/* Body of the landing page */
.App-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  padding: 55px 20px;
  background-color: #ede8de;
}

.App-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.App-body-text {
  fontsize: 18px;
  textalign: start;
  lineheight: 1.5;
  color: #597eb7;
}

.text-container-app {
  width: 50%;
}

.body-text-header {
  font-size: 28px;
  text-align: start;
  line-height: 1.5;
  color: #597eb7;
  font-weight: bold;
}

.Card-Container-Opt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  padding: 55px 20px;
  background-color: #ede8de;
  z-index: 10;
  border-radius: 10px;
  border-width: 1px;
  border-style: dashed;
  border-color: #597eb7;
}

/* Styles for tablets (e.g., iPad) */
@media (max-width: 768px) {
  .App-body {
    padding: 30px;
    flex-direction: column;
    min-height: 100%;
    min-width: 100%;
  }

  .App-body-container {
    /* Modify .App-body-container styles for tablets */
    height: 100%;
    flex-direction: column;
  }

  .App-body-text {
    /* Modify .App-body-text styles for tablets */
    font-size: 16px;
    text-align: start;
    line-height: 1.5;
    color: #597eb7;
  }

  .text-container-app {
    /* Modify .text-container-app styles for tablets */
    width: 100%;
  }

  .body-text-header {
    /* Modify .body-text-header styles for tablets */
    fontsize: 24px;
    textalign: start;
    lineheight: 1.5;
    color: #597eb7;
    fontweight: bold;
  }
}

/* Styles for mobile devices (e.g., smartphones) */
@media (max-width: 480px) {
  .App-body {
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }

  .App-body-container {
    /* Modify .App-body-container styles for tablets */
    height: 100%;
    flex-direction: column;
  }

  .App-body-text {
    /* Modify .App-body-text styles for tablets */
    font-size: 16px;
    text-align: start;
    line-height: 1.5;
    color: #597eb7;
  }

  .text-container-app {
    /* Modify .text-container-app styles for tablets */
    width: 100%;
  }

  .body-text-header {
    /* Modify .body-text-header styles for tablets */
    font-size: 24px;
    text-align: start;
    line-height: 1.5;
    color: #597eb7;
    font-weight: bold;
  }
}

/* Footer styles */
.App-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 10vh;
  padding: 25px;
  background-color: #ede8de;
}

.footer-column {
  display: flex;
  flex-direction: column;
  text-align: start;
}

/* Styles for tablets (e.g., iPad) */
@media (max-width: 768px) {
  .App-footer {
    /* Modify .App-footer styles for tablets */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
  }

  .footer-column {
    /* Modify .footer-column styles for tablets */
    display: flex;
    flex-direction: row;
    text-align: center;
  }
}

/* Styles for mobile devices (e.g., smartphones) */
@media (max-width: 480px) {
  .App-footer {
    /* Modify .App-footer styles for tablets */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
  }

  .footer-column {
    /* Modify .footer-column styles for tablets */
    display: flex;
    flex-direction: column;
    text-align: start;
  }
}
